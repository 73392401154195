// third-party
import { FormattedMessage } from 'react-intl';

// assets
import enums from 'utils/enums';
import {
  PersonOutline,
  AccountTreeOutlined,
  ArticleOutlined,
  SyncAltOutlined,
  PhonelinkSetupOutlined,
  NotificationsActiveOutlined,
  Groups2,
  TabletMacOutlined
} from '@mui/icons-material';

// icons
const icons = {
  PersonOutline,
  AccountTreeOutlined,
  ArticleOutlined,
  SyncAltOutlined,
  PhonelinkSetupOutlined,
  NotificationsActiveOutlined,
  Groups2,
  TabletMacOutlined
};

// ==============================|| MENU ITEMS - MAIN ||============================== //
const admin = {
  id: 'admin',
  type: 'group',
  title: <FormattedMessage id='admin' />,
  roleTags: [enums.userRole.CJAdmin, enums.userRole.SuperAdmin, enums.userRole.User, enums.userRole.SiteManager],
  children: [
    {
      id: 'sync',
      title: <FormattedMessage id='sync' />,
      type: 'item',
      url: 'dashboard/sync',
      icon: icons.SyncAltOutlined,
      roleTags: [enums.userRole.SuperAdmin],
      isShowFilter: true,
      showTitle: true
    },
    {
      id: 'systemUserList',
      title: <FormattedMessage id='systemUserList' />,
      subTitle: 'Manage Users',
      type: 'item',
      url: 'admin/users',
      icon: icons.PersonOutline,
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      isShowFilter: true
    },
    {
      id: 'employeeList',
      title: <FormattedMessage id='employeeList' />,
      subTitle: 'Manage POS Employees',
      type: 'item',
      url: 'admin/employees',
      icon: icons.Groups2,
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      isShowFilter: true
    },
    {
      id: 'sitelist',
      title: <FormattedMessage id='sitelist' />,
      subTitle: 'Manage Sites',
      type: 'item',
      url: 'admin/sites',
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin, enums.userRole.User, enums.userRole.SiteManager],
      icon: icons.AccountTreeOutlined,
      isShowFilter: true
    },
    {
      id: 'rules',
      title: <FormattedMessage id='rules' />,
      subTitle: 'Manage Rules',
      type: 'item',
      url: 'admin/rules',
      icon: icons.ArticleOutlined,
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      isShowFilter: true
    },
    {
      id: 'kiosk',
      title: <FormattedMessage id='kiosk' />,
      subTitle: 'Manage Kiosks',
      type: 'item',
      url: 'admin/kiosks',
      icon: icons.PhonelinkSetupOutlined,
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      isShowFilter: true
    },
    {
      id: 'notification',
      title: <FormattedMessage id='notification' />,
      subTitle: 'Push Notifications',
      type: 'item',
      url: 'admin/notification',
      roleTags: [enums.userRole.SuperAdmin],
      icon: icons.NotificationsActiveOutlined,
      isShowFilter: true
    },
    {
      id: 'pos',
      title: <FormattedMessage id='pos' />,
      subTitle: 'Manage Pos',
      type: 'item',
      url: 'admin/pos',
      roleTags: [enums.userRole.SuperAdmin, enums.userRole.CJAdmin],
      icon: icons.TabletMacOutlined,
      isShowFilter: true
    }
  ]
};

export default admin;
